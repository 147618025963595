


.hero-landing-page{
    background-color: $Gray-Shade-4;
    background-image: url('/assets/img/landing-page-bg.jpg');
    background-position: 50% 550%;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    .container-fluid{
        .row-custom{
            align-items: center;
            .col-content-otr{
                @include breakpoint($secreen-max-lg){
                    width: 70%;
                }
                @include breakpoint($secreen-max-md){
                    width: 100%;
                }
                .col-content-inr{
                    padding: 128px 0 144px 0;
                    position: relative;
                    z-index: 10;
                    @include breakpoint($secreen-max-md){
                        padding: 80px 0;
                    }
                    .heading{
                        color: $Black-Color;
                    }
                    .desc{
                        color: $Gray-Shade-1;
                        padding: 16px 0 32px 0;
                    }
                    .icons-ul{
                        display: flex;
                        align-items: center;
                        .icons-li{
                            &:not(:last-child){
                                margin-right: 30px;
                            }
                            .icon{
                                height: 50px;
                            }
                        }
                    }
                }
            }
            .col-img-otr{
                .col-img-inr{
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 2;
                    height: 100%;
                    @include breakpoint($secreen-max-md){
                        display: none;
                    }
                    .hero-img{
                        height: 100%;
                    }
                }
            }
        }
    }
    .shape-img{
        position: absolute;
        bottom: 0;
        left: 0;
        transform: matrix(1, 0, 0, -1, 0, 0);
    }
    .responsive-img{
        position: relative;
        z-index: 10;
        @include breakpoint($secreen-min-lg){
            display: none;
        }
    }
}
.pages-main{
    margin: 128px 0 88px 0;
    @include breakpoint($secreen-max-sm){
        margin: 80px 0 50px 0;
    }
    .container-fluid{
        .pages-heading{
            text-align: center;
            .heading{
                color: $Black-Color;
            }
            .desc{
                color: $Gray-Shade-1;
                padding: 16px 0;
            }
        }
        .tab-otr{
            .tab-ul{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                margin: 0 0 72px 0;
                .tab-li{
                    &:not(:last-child){
                        margin-right: 20px;
                    }
                    .nav-item{
                        padding: 4px 16px;
                        border-radius: 12px;
                        font-size: $font-size-bs;
                        line-height: $line-height-bsb;
                        font-family: $font-family-DMSans-Bold;
                        color: $Gray-Shade-1;
                        background-color: $Gray-Shade-4;
                        cursor: pointer;
                        margin: 12px 0 0 0;
                    }
                    .active{
                        background-color: $Primary-Color-Home-1;
                        color: $White-Color;
                        border: 1px solid transparent;
                    }
                }
            }
        }
        .row-custom{
            .col-otr{
                display: flex;
                align-items: center;
                justify-content: center;
                .col-inr{
                    border-radius: 16px;
                    text-align: center;
                    .img-otr{
                        .img-page{
                            border-radius: 16px;
                            box-shadow: $box-shadow-custom;
                        }
                    }
                    .page-title{
                        color: $Black-Color;
                        margin: 12px 0 0 0;
                        transition: .2s;
                        &:hover{
                            color: $Primary-Color-Home-1;
                        }
                    }
                }
                .box{
                    margin-bottom: 40px;
                }
            }
        }
    }
}
.footer-landing{
    position: relative;
    overflow: hidden;
    background-color: $Gray-Shade-4;
    padding: 128px 0 140px 0;
    &::after{
        content: "";
        position: absolute;
        width: 400px;
        height: 400px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        background-color: $Primary-Color-Home-1;
        filter: blur(300px);
    }
    .container-fluid{
        .wrapper{
            text-align: center;
            position: relative;
            z-index: 10;
            .heading{
                color: $Black-Color;
                br{
                    @include breakpoint($secreen-max-md){
                        display: none;
                    }
                }
            }
            .desc{
                color: $Gray-Shade-1;
                padding: 16px 0 28px 0;
                br{
                    @include breakpoint($secreen-max-md){
                        display: none;
                    }
                }
            }
            .action-otr{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .circle-1{
        position: absolute;
        right: 0;
        top: 0;
        opacity: 25%;
    }
    .circle-2{
        position: absolute;
        left: 0;
        bottom: 0;
        opacity: 25%;
    }
}
.copyright{
    padding: 32px 0;
    .container-fluid{
        .copy{
            color: $Gray-Shade-1;
            text-align: center;
            .visto{
                display: inline;
                color: $Primary-Color-Home-1;
            }
        }
    }
}