

/*------------------------------------------
          About Start Here
-------------------------------------------*/

.home3-hero{
    margin: 96px 0 128px 0;
    @include breakpoint($secreen-max-sm){
        margin: 48px 0 80px 0;
    }
    .container-fluid{
        .row-content{
            margin: 0 0 48px 0;
            align-items: center;
            .col-head-otr{
                .col-head-inr{
                    position: relative;
                    .head{
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-image: linear-gradient(to right, #366CE3, #D0FC00);
                        position: relative;
                        z-index: 2;
                    }
                }
            }
            .col-text-otr{
                .col-text-inr{
                    .desc{
                        color: $Gray-Shade-1;
                        margin: 0 0 24px 0;
                    }
                    .btn-otr{
                        display: flex;
                        align-items: center;
                        .btn-explore{
                            margin: 0 16px 0 0;
                        }
                    }
                }
            }
        }
        .wrapper{
            width: 100%;
            height: 600px;
            background-color: $Gray-Shade-4;
            border-radius: 24px;
            overflow: hidden;
            position: relative;
            @include breakpoint($secreen-max-md){
                height: 350px;
            }
            @include breakpoint($secreen-max-sm){
                height: 300px;
            }
            @include breakpoint($secreen-xs){
                height: 200px;
            }
            .row-imgs{
                position: absolute;
                top: 50%;
                left: 0;
                z-index: 2;
                transform: translateY(-50%) rotate(-15deg);
                .col-img-otr{
                    @include breakpoint($secreen-xs){
                        max-width: 25%;
                        width: 25%;
                    }
                    .col-img-inr{
                        &:not(:last-child){
                            margin-bottom: 30px;
                            @include breakpoint($secreen-xs){
                                margin-bottom: 24px;
                            }
                        }
                        .about-img{
                            border-radius: 16px;
                        }
                    }
                }
                .box-5,.box-6{
                    @include breakpoint($secreen-xs){
                        display: none;
                    }
                }
            }
        }
    }
}

/*------------------------------------------
          About End Here
-------------------------------------------*/