

/*------------------------------------------
        User profile Start Here
-------------------------------------------*/

.user-profile-otr{
    .container-fluid{
        .wrapper{
            border-radius: 24px;
            border: 1px solid $Gray-Shade-3;
            .user-img{
                background-image: url('/assets/img/cover-img.jpg');
                background-size: cover;
                border-radius: 24px 24px 0 0;
                padding: 40px;
                .creator-img-otr{
                    width: 146px;
                    height: 146px;
                    border-radius: 16px;
                    background-color: $White-Color;
                    border: 4px solid $White-Color;
                    .creator-img{
                        width: 100%;
                        height: 100%;
                        border-radius: 16px;
                    }
                }
            }
            .another-user{
                padding: 0 40px;
                @include breakpoint($secreen-xs){
                    padding: 0 28px;
                }
                .another-user-top{
                    padding: 20px 0;
                    border-bottom: 1px solid $Gray-Shade-3;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    @include breakpoint($secreen-max-sm){
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 20px 0 28px 0;
                    }
                    .another-user-left{
                        display: flex;
                        align-items: center;
                        @include breakpoint($secreen-max-sm){
                            margin: 0 0 30px 0;
                        }
                        @include breakpoint($secreen-xl2){
                            flex-direction: column;
                            align-items: flex-start;
                        }
                        .user-main{
                            display: flex;
                            align-items: center;
                            margin-right: 48px;
                            @include breakpoint($secreen-xl2){
                                margin: 0 0 16px 0;
                            }
                            .user-head{
                                color: $Black-Color;
                            }
                            .check-otr{
                                width: 24px;
                                height: 24px;
                                background-color: $Primary-Color-Home-1;
                                border-radius: 100%;
                                position: relative;
                                margin-left: 12px;
                                .check-icon{
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                    width: 14px;
                                }
                            }
                        }
                        .address-main{
                            display: flex;
                            align-items: center;
                            .address{
                                color: $Gray-Shade-1;
                            }
                            .copy-icon-otr{
                                width: 40px;
                                height: 40px;
                                border-radius: 100%;
                                background-color: $Gray-Shade-4;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-left: 12px;
                                .copy-icon{
                                    path{
                                        stroke: $Gray-Shade-2;
                                    }
                                }
                            }
                        }
                    }   
                    .another-user-right{
                        display: flex;
                        align-items: center;
                        @include breakpoint($secreen-xs){
                            flex-direction: column;
                            align-items: flex-start;
                        }
                        .icons-main{
                            display: flex;
                            align-items: center;
                            .icon-flag{
                                width: 52px;
                                height: 52px;
                                border-radius: 100%;
                                margin-right: 12px;
                                background-color: $Gray-Shade-4;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                            }
                            .icon-div{
                                &:not(:last-child){
                                    margin-right: 16px;
                                }
                            }
                            .icon-otr{
                                width: 52px;
                                height: 52px;
                                border-radius: 100%;
                                margin-right: 12px;
                                background-color: $Gray-Shade-4;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                cursor: pointer;
                                .icon{
                                    path{
                                        stroke: $Gray-Shade-2;
                                    }
                                }
                                .share-hover{
                                    position: absolute;
                                    width: 330px;
                                    height: 130px;
                                    top: -150px;
                                    left: 50%;
                                    transform: translateX(-92%);
                                    background-color: $White-Color;
                                    border-radius: 24px;
                                    padding: 20px 28px 24px 28px;
                                    z-index: 999;
                                    opacity: 0;
                                    visibility: hidden;
                                    transition: .3s;
                                    text-align: center;
                                    .heading{
                                        color: $Black-Color;
                                        margin: 0 0 12px 0;
                                    }
                                    .icon-ul{
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        width: 100%;
                                        .icon-li{
                                            .icon-a{
                                                &:hover{
                                                    .icon{
                                                        path{
                                                            stroke: $Primary-Color-Home-1;
                                                        }
                                                    }
                                                }
                                                .icon{
                                                    path{
                                                        transition: .3s;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .block-3{
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                        .follower-main{
                            display: flex;
                            align-items: center;
                            margin: 0 48px;
                            @include breakpoint($secreen-max-md){
                                margin: 0 24px;
                            }
                            @include breakpoint($secreen-xs){
                                margin: 30px 0;
                            }
                            .follow-inr{
                                cursor: pointer;
                                &:not(:last-child){
                                    margin-right: 24px;
                                }
                                .num{
                                    color: $Black-Color;
                                }
                                .follow{
                                    color: $Gray-Shade-2;
                                }
                            }
                            .modal{
                                .modal-dialog{
                                    max-width: 420px;
                                    height: 620px;
                                    margin: 0 auto;
                                    border-radius: 24px;
                                    overflow: hidden;
                                    .modal-content{
                                        height: 100%;
                                        height: 620px;
                                        overflow-y: scroll;
                                        padding: 28px 36px 36px 36px;
                                        border-radius: 24px;
                                        background-color: $White-Color;
                                        border: none;
                                        position: relative;
                                        &::-webkit-scrollbar{
                                            width: 0.1px;
                                        }
                                        .heading{
                                            color: $Black-Color;
                                            margin: 0 0 24px 0;
                                        }
                                        .create-inr{
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                            &:not(:last-child){
                                                margin: 0 0 24px 0;
                                            }
                                            .profile-otr{
                                                display: flex;
                                                align-items: center;
                                                .create-img{
                                                    position: relative;
                                                    margin: 0 16px 0 0;
                                                    .img-create{
                                                        width: 60px;
                                                        border-radius: 16px;
                                                    }
                                                    .check-otr{
                                                        position: absolute;
                                                        bottom: 0;
                                                        right: 0;
                                                        width: 20px;
                                                        height: 20px;
                                                        background-color: $Primary-Color-Home-1;
                                                        border-radius: 100%;
                                                        border: 2px solid $White-Color;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        .check-icon{
                                                            width: 10px;
                                                        }
                                                    }
                                                }
                                                .create-content{
                                                    .create-p{
                                                        color: $Gray-Shade-2;
                                                    }
                                                    .create-pb{
                                                        color: $Black-Color;
                                                    }
                                                }
                                            }
                                            .btn-otr{

                                            }
                                        }
                                        .close-icon-otr{
                                            position: absolute;
                                            top: 28px;
                                            right: 36px;
                                            &:hover{
                                                cursor: pointer;
                                            }
                                            .icon-close{
                                                
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .action-otr{
                            display: flex;
                            .btn-follow{
                                width: auto;
                            }
                        }
                    }
                }
                .another-user-about{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 48px 0;
                    @include breakpoint($secreen-max-sm){
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 20px 0 28px 0;
                    }
                    .another-about-left{
                        display: flex;
                        align-items: center;
                        @include breakpoint($secreen-xl2){
                            flex-direction: column;
                            align-items: flex-start;
                        }
                        @include breakpoint($secreen-max-lg){
                            width: 40%;
                        }
                        @include breakpoint($secreen-max-md){
                            width: 70%;
                            margin: 0;
                        }
                        @include breakpoint($secreen-max-sm){
                            width: 100%;
                            margin: 0 0 30px 0;
                        }
                        .about-head{
                            color: $Black-Color;
                            @include breakpoint($secreen-xl2){
                                padding: 0 0 8px 0;
                            }
                        }
                        .about-desc{
                            color: $Gray-Shade-2;
                            margin-left: 32px;
                            @include breakpoint($secreen-max-xxl){
                                br{
                                    display: none;
                                }
                            }
                            @include breakpoint($secreen-xl2){
                                margin: 0;
                            }
                            @include breakpoint($secreen-max-md){
                                padding: 0 72px 0 0;
                            }
                            @include breakpoint($secreen-max-sm){
                                padding: 0;
                            }
                        }
                    }
                    .another-about-right{
                        display: flex;
                        align-items: center;
                        @include breakpoint($secreen-max-md){
                            flex-direction: column;
                            align-items: flex-start;
                        }
                        .visto-otr{
                            display: flex;
                            align-items: center;
                            .link-icon{
                                margin-right: 12px;
                                &:hover{
                                    cursor: pointer;
                                }
                                .link{
                                    &:hover{
                                        path{
                                            stroke: $Primary-Color-Home-1;
                                        }
                                    }
                                    path{
                                        stroke: $Gray-Shade-2;
                                        transition: .3s;
                                    }
                                }
                            }
                            .visto{
                                color: $Primary-Color-Home-1;
                            }
                        }
                        .icon-ul{
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            margin-left: 48px;
                            @include breakpoint($secreen-max-md){
                                margin: 0;
                            }
                            .icon-li{
                                @include breakpoint($secreen-max-md){
                                    margin-top: 16px;
                                }
                                &:not(:last-child){
                                    margin-right: 16px;
                                }
                                .icon-a{
                                    .icon{
                                        &:hover{
                                            path{
                                                stroke: $Primary-Color-Home-1;
                                            }
                                        }
                                        path{
                                            stroke: $Gray-Shade-2;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*------------------------------------------
        User profile End Here
-------------------------------------------*/

/*------------------------------------------
        Explore Artworks Start Here
-------------------------------------------*/

.explore-artwork-creator{
    overflow: hidden;
    padding: 72px 0 128px 0;
    @include breakpoint($secreen-max-sm){
        padding: 80px 0 !important;
    }
    .container-fluid{
        .explore-artwork-inr{
            .teb-main{
                @include breakpoint($secreen-xs){
                    overflow-x: scroll;
                }
                &::-webkit-scrollbar {
                    width: 100%;
                    height: .1px;
                    background-color: #EEEEEE;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #ACACAC;
                    border-radius: 2px;
                }
                .tab-otr{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    @include breakpoint($secreen-xs){
                        width: 510px;
                    }
                    .tab-inr{
                        .tabs{
                            display: flex;
                            align-items: center;
                            .tab-link{
                                &:not(:last-child){
                                    margin-right: 24px;
                                }
                                .tab-p{
                                    padding: 4px 16px;
                                    border-radius: 12px;
                                    background-color: $Gray-Shade-4;
                                    color: $Gray-Shade-1;
                                    transition: .3s;
                                    &:hover{
                                        cursor: pointer;
                                    }
                                }
                            }
                            .active{
                                .tab-p{
                                    background-color: $Primary-Color-Home-1;
                                    color: $White-Color;
                                }
                            }
                        }
                    }
                    .slect-main{
                        display: flex;
                        align-items: center;
                        .select {
                            cursor: pointer;
                            display: inline-block;
                            position: relative;
                            margin-top: 24px;
                            &:not(:last-child){
                                margin-right: 24px;
                            }
                            .select-hidden {
                                display: none;
                                visibility: hidden;
                                padding-right: 10px;
                            }
                            .select-styled {
                                background-color: $Gray-Shade-4;
                                color: $Gray-Shade-1;
                                padding: 4px 32px 4px 16px;
                                border-radius: 12px;
                                font-size: $font-size-bs;
                                line-height: $line-height-bsb;
                                font-family: $font-family-DMSans-Bold;
                                &:after {
                                    content:"";
                                    width: 12px;
                                    height: 10px;
                                    position: absolute;
                                    background-image: url(/assets/img/artwork-page-arrow.svg);
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    top: 16px;
                                    right: 16px;
                                }
                                &:active, &.active {
                                    &:after {
                                        top: 16px;
                                        border-color: transparent transparent $White-Color transparent;
                                    }
                                }
                            }
                            .select-options {
                                display: none; 
                                position: absolute;
                                top: 130%;
                                width: 100%;
                                z-index: 999;
                                background-color: $White-Color;
                                border-radius: 12px;
                                padding: 16px 0;
                                li {
                                    font-size: $font-size-bs;
                                    line-height: $line-height-bsb;
                                    font-family: $font-family-DMSans-Bold;
                                    color: $Gray-Shade-1;
                                    border-radius: 12px;
                                    padding: 0 24px 0 24px;
                                    transition: .3s;
                                    &:not(:last-child){
                                        margin: 0 0 4px 0;
                                    }
                                    &:hover {
                                        color: $Primary-Color-Home-1;
                                        background: transparent;
                                        border-radius: 0;
                                    }
                                    &[rel="hide"] {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .line{
                display: flex;
                flex: 1;
                height: 1px;
                background-color: $Gray-Shade-3;
                margin: 49px 0;
            }
            .row-custom-main{
                .tab-content{
                    display: none;
                    opacity: 0;
                    transform: translateY(15px);
                    animation: fadeIn 0.5s ease 1 forwards;
                    .row-custom-inr{
                        .col-otr{
                            padding: 0 12px;
                            @include breakpoint($secreen-max-xxl){
                                width: 33%;
                            }
                            @include breakpoint($secreen-max-md){
                                width: 50%;
                            }
                            @include breakpoint($secreen-custom){
                                width: 100%;
                            }
                            .col-inr{
                                padding: 24px;
                                border: 1px solid $Gray-Shade-3;
                                border-radius: 24px;
                                transition: .3s;
                                &:hover{
                                    border: 1px solid transparent;
                                    box-shadow: $box-shadow-custom;
                                }
                                .img-otr{
                                    position: relative;
                                    .img-inr{
                                        border-radius: 16px;
                                    }
                                    .timer-otr{
                                        position: absolute;
                                        bottom: 0;
                                        left: 0;
                                        width: 120px;
                                        height: 40px;
                                        border: 1px solid $White-Color;
                                        background-color: $Primary-Color-Home-1;
                                        border-radius: 12px;
                                        .countdown{
                                            position: absolute;
                                            left: 65%;
                                            top: 50%;
                                            transform: translate(-50%, -50%);
                                            color: $White-Color;
                                            width: 100%;
                                        }
                                    }
                                }
                                .time-main{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding: 24px 0 20px 0;
                                    .users-main{
                                        position: relative;
                                        display: flex;
                                        align-items: center;
                                        .create-img-otr{
                                            position: relative;
                                            &:hover{
                                                .hover-box1{
                                                    bottom: 40px;
                                                    padding-bottom: 25px;
                                                    opacity: 1;
                                                    visibility: visible;
                                                }
                                                .hover-box2{
                                                    bottom: 40px;
                                                    padding-bottom: 25px;
                                                    opacity: 1;
                                                    visibility: visible;
                                                }
                                            }
                                            .create-img{
                                                .img-create{
                                                    width: 40px;
                                                    border-radius: 12px;
                                                }
                                                .check-otr{
                                                    position: absolute;
                                                    bottom: 0;
                                                    right: 0;
                                                    width: 16px;
                                                    height: 16px;
                                                    background-color: $Primary-Color-Home-1;
                                                    border-radius: 100%;
                                                    border: 2px solid $White-Color;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    .check-icon{
                                                        width: 8px;
                                                    }
                                                }
                                            }
                                            .left-create{
                                                margin-right: 8px;
                                            }
                                            .hover-box{
                                                position: absolute;
                                                bottom: 60px;
                                                padding-bottom: 50px;
                                                transition: .3s;
                                                z-index: 10;
                                                .hover-box-inr{
                                                    width: 330px;
                                                    background-color: $White-Color;
                                                    border-radius: 24px;
                                                    padding: 32px;
                                                    box-shadow: $box-shadow-custom;
                                                    backdrop-filter: 15px;
                                                    .user-info{
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: space-between;
                                                        .create-img{
                                                            position: relative;
                                                            .img-create{
                                                                width: 60px;
                                                                border-radius: 16px;
                                                            }
                                                            .check-otr{
                                                                position: absolute;
                                                                bottom: 0;
                                                                right: 0;
                                                                width: 20px;
                                                                height: 20px;
                                                                background-color: $Primary-Color-Home-1;
                                                                border-radius: 100%;
                                                                border: 2px solid $White-Color;
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: center;
                                                                .check-icon{
                                                                    width: 10px;
                                                                }
                                                            }
                                                        }
                                                        .follow-btn{
                                                            width: auto;
                                                        }
                                                    }
                                                    .post-title{
                                                        padding: 20px 0 8px 0;
                                                        color: $Black-Color;
                                                    }
                                                    .address-main{
                                                        display: inline-flex;
                                                        align-items: center;
                                                        padding: 0 0 16px 0;
                                                        .address{
                                                            color: $Gray-Shade-2;
                                                            padding-right: 8px;
                                                        }
                                                        .copy-icon{
                                                            path{
                                                                stroke: $Gray-Shade-3;
                                                            }
                                                        }
                                                    }
                                                    .post-desc{
                                                        color: $Gray-Shade-2;
                                                    }
                                                }
                                            }    
                                            .hover-box1{
                                                left: -20px;
                                                opacity: 0;
                                                visibility: hidden;
                                                @include breakpoint($secreen-max-md){
                                                    display: none;
                                                }
                                            }
                                            .hover-box2{
                                                left: -70px;
                                                opacity: 0;
                                                visibility: hidden;
                                                @include breakpoint($secreen-max-md){
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                    .heart-main{
                                        display: flex;
                                        align-items: center;
                                        .heart-otr{
                                            width: 40px;
                                            height: 40px;
                                            border-radius: 100%;
                                            margin-right: 12px;
                                            background-color: $Gray-Shade-4;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            .heart-icon{
                                                position: relative;
                                                top: 1px;
                                                path{
                                                    transition: .5s;
                                                }
                                            }
                                        }
                                        .num{
                                            color: $Gray-Shade-2;
                                        }
                                    }
                                }
                                .box-head{
                                    color: $Black-Color;
                                    padding: 0 0 24px 0;
                                    display: inline-flex;
                                }
                                .bid-main{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding: 12px 20px;
                                    background-color: $Gray-Shade-4;
                                    border-radius: 16px;
                                    .bid{
                                        color: $Gray-Shade-2;
                                    }
                                    .eth{
                                        color: $Black-Color;
                                    }
                                }
                            }
                            .box1,.box2,.box3,.box4{
                                margin-bottom: 24px;
                            }
                            .box1,.box2,.box3,.box4,.box5,.box6{
                                @include breakpoint($secreen-max-md){
                                    margin-bottom: 24px;
                                }
                            }
                            .box1,.box2,.box3,.box4,.box5,.box6,.box7{
                                @include breakpoint($secreen-custom){
                                    margin-bottom: 24px;
                                }
                            }
                        }
                    }
                }
                .active{
                    display: block;
                }
            }
        }
    }
}

/*------------------------------------------
        Explore Artworks End Here
-------------------------------------------*/