

/*------------------------------------------
        Blog Detail Start Here
-------------------------------------------*/

.blog-detail-main{
    padding: 96px 0 128px 0;
    @include breakpoint($secreen-max-sm){
        padding: 56px 0 80px 0 !important;
    }
    .container-fluid{
        .row-detail{
            display: flex;
            align-items: center;
            justify-content: center;
            .col-detail-otr{
                .col-detail-inr{
                    .head{
                        color: $Black-Color;
                        @include breakpoint($secreen-max-xxl){
                            br{
                                display: none;
                            }
                        }
                    }
                }
            }
            .line{
                width: 100%;
                height: 1px;
                background-color: $Gray-Shade-3;
                margin: 36px 0;
            }
            .col-detail-otr-2{
                .col-detail-inr-2{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    position: relative;
                    background-color: $Gray-Shade-4;
                    border-radius: 24px;
                    padding: 56px 45px;
                    overflow: hidden;
                    margin: 0 0 40px 0;
                    @include breakpoint($secreen-xs){
                        padding: 28px;
                    }
                    &::after{
                        content: '';
                        position: absolute;
                        width: 500px;
                        height: 500px;
                        background-color: $Primary-Color-Home-1;
                        filter: blur(300px);
                        top: 50%;
                        left: 50%;
                        transform: translate(-50% , -50%);
                        opacity: 25%;
                    }
                    .blog-img-otr{
                        .blog-img-inr{
                            @include breakpoint($secreen-max-sm){
                                position: relative;
                            }
                            .img-box{
                                position: relative;
                                width: 430px;
                                border-radius: 24px;
                                margin: 0 0 24px 0;
                                z-index: 2;
                                @include breakpoint($secreen-xs){
                                    width: 100%;
                                }
                            }
                            .icon-main{
                                position: absolute;
                                right: 60px;
                                top: 50%;
                                transform: translateY(-50%);
                                z-index: 2;
                                @include breakpoint($secreen-max-sm){
                                    right: -26px;
                                    transform: translateY(-59%);
                                }
                                .icon-otr{
                                    width: 52px;
                                    height: 52px;
                                    border-radius: 100%;
                                    background-color: $White-Color;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    position: relative;
                                    cursor: pointer;
                                    .icon{
                                        path{
                                            stroke: $Gray-Shade-2;
                                        }
                                    }
                                    .share-hover{
                                        position: absolute;
                                        width: 330px;
                                        height: 130px;
                                        top: -150px;
                                        left: 50%;
                                        transform: translateX(-92%);
                                        background-color: $White-Color;
                                        border-radius: 24px;
                                        padding: 20px 28px 24px 28px;
                                        z-index: 999;
                                        opacity: 0;
                                        visibility: hidden;
                                        transition: .3s;
                                        text-align: center;
                                        .heading{
                                            color: $Black-Color;
                                            margin: 0 0 12px 0;
                                        }
                                        .icon-ul{
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                            width: 100%;
                                            .icon-li{
                                                .icon-a{
                                                    &:hover{
                                                        .icon{
                                                            path{
                                                                stroke: $Primary-Color-Home-1;
                                                            }
                                                        }
                                                    }
                                                    .icon{
                                                        path{
                                                            transition: .3s;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .block-3{
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                                .icon-inr{
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin: 16px 0 0 0;
                                    .icon-flag{
                                        position: absolute;
                                    }
                                    .circle{
                                        width: 52px;
                                        height: 52px;
                                        background-color: $White-Color;
                                        border-radius: 100%;
                                    }
                                }
                            }
                        }
                    }
                    .circle-img-1{
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        opacity: 25%;
                    }
                    .circle-img-2{
                        position: absolute;
                        right: 0;
                        top: 0;
                        opacity: 25%;
                    }
                    .post-main{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background-color: $White-Color;
                        border-radius: 24px;
                        padding: 24px 32px;
                        width: 430px;
                        position: relative;
                        z-index: 2;
                        @include breakpoint($secreen-xs){
                            width: 100%;
                        }
                        .img-otr{
                            display: flex;
                            align-items: center;
                            .post-by{
                                border-radius: 16px;
                                margin: 0 16px 0 0;
                                width: 60px;
                            }
                            .content{
                                display: flex;
                                align-items:flex-start;
                                justify-content: flex-start;
                                flex-direction: column;
                                .desc{
                                    color: $Gray-Shade-2;
                                }
                                .desc-2{
                                    color: $Black-Color;
                                }
                            }
                        }
                        .date-otr{
                            display: flex;
                            align-items: flex-end;
                            justify-content: flex-end;
                            flex-direction: column;
                            @include breakpoint($secreen-max-sm){
                                display: none;
                            }
                            .desc-3{
                                color: $Primary-Color-Home-1;
                            }
                            .desc-4{
                                color: $Gray-Shade-2;
                            }
                        }
                    }
                }
                .col-detail-inr-3{
                    .head-1{
                        color: $Black-Color;
                        &:not(:first-child){
                            margin: 24px 0 0 0;
                        }
                    }
                    .desc{
                        color: $Gray-Shade-1;
                        margin: 16px 0 0 0;
                    }
                    .head-2{
                        color: $Black-Color;
                        margin: 20px 0 0 0;
                    }
                    .desc-two{
                        color: $Gray-Shade-1;
                        margin: 12px 0 0 0;
                    }
                    .content-otr{
                        background-color: $Gray-Shade-4;
                        border-radius: 24px;
                        padding: 28px 36px;
                        margin: 28px 0 24px 0;
                        .desc-inr{
                            color: $Gray-Shade-1
                        }
                    }
                }
            }
        }
    }
}

/*------------------------------------------
        Blog Detail End Here
-------------------------------------------*/