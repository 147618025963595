

.nav-home6{
    .container-fluid{
        .nav-otr{
            position: relative;
            .nav-inr{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 32px 0;
                @include breakpoint($secreen-max-md){
                    padding: 24px 0;
                }
                .burger-menu{
                    display: flex;
                    align-items: center;
                    .burger-icon-otr{
                        width: 52px;
                        height: 52px;
                        border-radius: 100%;
                        background-color: $Gray-Shade-4;
                        position: relative;
                        margin-right: 12px;
                        .burger-icon{
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            &:hover{
                                cursor: pointer;
                                path{
                                    stroke: $Primary-Color-Home-1;
                                }
                            }
                            path{
                                stroke: $Gray-Shade-2;
                                transition: .3s;
                            }
                        }
                    }
                    .logo-otr{
                        .logo-img{
                            width: 100px;
                        }
                    }
                }
                .input-main{
                    position: relative;
                    flex: 1;
                    margin: 0 30px;
                    @include breakpoint($secreen-max-md){
                        margin: 0 0 0 30px;
                    }
                    @include breakpoint($secreen-max-sm){
                        display: none;
                    }
                    .input{
                        width: 100%;
                        padding-right: 52px;
                    }
                    .search-icon{
                        position: absolute;
                        right: 24px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
                .search-circle{
                    width: 52px;
                    height: 52px;
                    background-color: $Gray-Shade-4;
                    border-radius: 100%;
                    position: relative;
                    @include breakpoint($secreen-min-sm){
                        display: none;
                    }
                    .search-icon{
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .modal-input{
                    .modal-dialog-input{
                        .modal-content-input{
                            background-color: transparent;
                            border: none;
                        }
                    }
                }
                .action-otr{
                    display: flex;
                    align-items: center;
                    @include breakpoint($secreen-max-md){
                        display: none;
                    }
                    .btn-create{
                        margin-right: 16px;
                    }
                }
            }
        }
    }
}

.hero-6{
    background-image: url('../assets/img/mesh-gradient-home6.png');
    background-size: cover;
    background-color: $Gray-Shade-4;
    padding: 128px 0 300px 0;
    margin: 0 0 128px 0;
    position: relative;
    overflow: hidden;
    @include breakpoint($secreen-max-sm){
        padding: 80px 0 260px 0;
    }
    &::after{
        content: "";
        position: absolute;
        left: 0;
        top: 100px;
        background-image: url('/assets/img/svg/home-6-circle.svg');
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
    }
    .container-fluid{
        .wrapper{
            .col-otr{
                margin: 0 auto;
                .col-inr{
                    padding: 110px 0;
                    position: relative;
                    z-index: 10;
                    @include breakpoint($secreen-max-md){
                        padding: 0;
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 700px;
                        height: 100%;
                        background-image: url('/assets/img/hero6-img.jpg');
                        border-radius: 24px;
                        z-index: -1;
                        @include breakpoint($secreen-max-lg){
                            width: 600px;
                        }
                        @include breakpoint($secreen-max-md){
                            display: none;
                        }
                    }
                    .heading{
                        padding: 28px 40px;
                        border-radius: 16px;
                        color: $Black-Color;
                        background-color: rgba(54, 108, 227, 0.15);
                        backdrop-filter: blur(250px);
                        display: inline-block;
                        @include breakpoint($secreen-max-md){
                            display: block;
                        }
                        @include breakpoint($secreen-max-sm){
                            background-color: transparent;
                            backdrop-filter: blur(0);
                            padding: 0;
                        }
                    }
                    .desc{
                        color: $Gray-Shade-1;
                        padding: 32px 0 32px 40px;
                        @include breakpoint($secreen-max-sm){
                            padding: 16px 0 24px 0;
                        }
                    }
                    .action-otr{
                        display: flex;
                        padding: 0 0 0 36px;
                        @include breakpoint($secreen-max-sm){
                            justify-content: flex-start;
                            align-items: flex-start;
                        }
                        @include breakpoint($secreen-max-sm){
                            padding: 0 0 0 0;
                        }
                        @include breakpoint($secreen-xxs){
                            flex-direction: column;
                        }
                        .btn-create{
                            margin-right: 16px;
                            @include breakpoint($secreen-xxs){
                                text-align: center;
                                width: 100%;
                                margin: 0 0 16px 0;
                            }
                        }
                        .btn-wallet{
                            @include breakpoint($secreen-xxs){
                                text-align: center;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .ethoz-div{
        position: absolute;
        bottom: 0;
        left: -100px;
        .ethoz-ul{
            display: flex;
            align-items: center;
            position: relative;
            overflow: hidden;
            .ethoz-li{
                &:not(:last-child){
                    margin-right: 20px;
                }
                font-size: 100px;
                line-height: 130px;
                color: $Black-Color;
                opacity: 7%;
                text-transform: uppercase;
                font-style: italic;
                font-family: $font-family-DMSans-Bold;
                margin: 0 0 -60px 0;
            }
        }
    }
}