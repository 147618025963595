

/*===================================

     Mode Button Start Here

===================================*/

.mode-btn-otr{
    position: fixed;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    z-index: 500;
    .btn-inr{
        width: 52px;
        height: 52px;
        border-radius: 100%;
        position: relative;
        overflow: hidden;
        &:hover{
            .mode-icon{
                transform: translate(-50%, -200%);
                filter: blur(10px);
            }
            .mode-icon-hover{
                transform: translate(-50%, -50%);
                filter: blur(0px);
            }
        }
        .mode-icon{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: .2s;
        }
        .mode-icon-hover{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, 120%);
            transition: .2s;
            filter: blur(10px);
        }
    }
    .dark{
        background-color: $Primary-Color-Home-1;
    }
    .light{
        background-color: $Secondary-Color-Home-1;
    }
}

/*===================================

     Mode Button End Here

===================================*/