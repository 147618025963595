

/*------------------------------------------
          About Start Here
-------------------------------------------*/

.about-main{
    margin: 96px 0 128px 0;
    @include breakpoint($secreen-max-sm){
        margin: 56px 0 80px 0;
    }
    .container-fluid{
        .row-content{
            margin: 0 0 72px 0;
            align-items: center;
            .col-head-otr{
                .col-head-inr{
                    .head{
                        color: $Black-Color;
                    }
                }
            }
            .col-text-otr{
                padding: 0 0 0 32px;
                @include breakpoint($secreen-max-md){
                    padding: 48px 0 0 0;
                }
                .col-text-inr{
                    padding: 28px 36px;
                    border: 1px solid #C4C4C4;
                    border-radius: 24px;
                    .desc{
                        color: $Gray-Shade-1;
                    }
                }
            }
        }
        .wrapper{
            width: 100%;
            height: 600px;
            background-color: $Gray-Shade-4;
            border-radius: 24px;
            overflow: hidden;
            position: relative;
            @include breakpoint($secreen-max-md){
                height: 350px;
            }
            @include breakpoint($secreen-max-sm){
                height: 300px;
            }
            @include breakpoint($secreen-xs){
                height: 200px;
            }
            .row-imgs{
                position: absolute;
                top: 50%;
                left: 0;
                z-index: 2;
                transform: translateY(-50%) rotate(-15deg);
                .col-img-otr{
                    @include breakpoint($secreen-xs){
                        max-width: 25%;
                        width: 25%;
                    }
                    .col-img-inr{
                        &:not(:last-child){
                            margin-bottom: 30px;
                            @include breakpoint($secreen-xs){
                                margin-bottom: 24px;
                            }
                        }
                        .about-img{
                            border-radius: 16px;
                        }
                    }
                }
                .box-5,.box-6{
                    @include breakpoint($secreen-xs){
                        display: none;
                    }
                }
            }
        }
    }
}

/*------------------------------------------
          About End Here
-------------------------------------------*/

/*------------------------------------------
          Statistics Start Here
-------------------------------------------*/

.statistics-main{
    margin: 128px 0;
    @include breakpoint($secreen-max-sm){
        margin: 80px 0;
    }
    .container-fluid{
        .wrapper{
            border-bottom: 1px solid $Gray-Shade-3;
            margin: 0 0 48px 0;
            .head{
                color: $Black-Color;
                padding: 0 0 36px 0;
            }
        }
        .row-statistics{
            .col-bidth-otr{
                .col-bidth-inr{
                    border-radius: 24px;
                    padding: 32px 36px 36px 36px;
                    background-color: $Gray-Shade-4;
                    position: relative;
                    overflow: hidden;
                    &::after{
                        content: '';
                        position: absolute;
                        width: 200px;
                        height: 200px;
                        border-radius: 100%;
                        background-color: $Primary-Color-Home-1;
                        filter: blur(160px);
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                    .heading{
                        position: relative;
                        z-index: 2;
                        color: $Black-Color;
                        margin: 0 0 8px 0;
                        .price{
                            color: $Gray-Shade-2;
                            @include breakpoint($secreen-xxs){
                                display: none;
                            }
                        }
                    }
                    .desc{
                        position: relative;
                        z-index: 2;
                        color: $Gray-Shade-1;
                    }
                    .circle-img{
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }
            .box-1,
            .box-2{
                margin-bottom: 30px;
            }
            .box-1,
            .box-2,
            .box-3,
            .box-4{
                @include breakpoint($secreen-max-md){
                    margin-bottom: 30px;
                }
            }
        }
    }
}

/*------------------------------------------
          Statistics End Here
-------------------------------------------*/

/*------------------------------------------
          Work Start Here
-------------------------------------------*/

.work-main{
    margin: 128px 0;
    @include breakpoint($secreen-max-sm){
        margin: 80px 0;
    }
    .container-fluid{
        .wrapper{
            display: flex;
            align-items: center;
            padding: 0 0 36px 0;
            border-bottom: 1px solid $Gray-Shade-3;
            margin: 0 0 48px 0;
            @include breakpoint($secreen-max-sm){
                flex-direction: column;
                align-items: flex-start;
            }
            .head{
                margin: 0 48px 0 0;
                color: $Black-Color;
                @include breakpoint($secreen-max-sm){
                    margin: 0 0 24px 0;
                }
            }
            .teb-main{
                .tabs{
                    display: flex;
                    align-items: center;
                    .tab-link-work{
                        &:not(:last-child){
                            margin-right: 24px;
                        }
                        .tab-p{
                            padding: 4px 16px;
                            border-radius: 12px;
                            background-color: $Gray-Shade-4;
                            color: $Gray-Shade-1;
                            transition: .3s;
                            &:hover{
                                cursor: pointer;
                            }
                            @include breakpoint($secreen-xxsx){
                                padding: 4px 15px;
                            }
                        }
                    }
                    .active{
                        .tab-p{
                            background-color: $Primary-Color-Home-1;
                            color: $White-Color;
                        }
                    }
                }
            }
        }
        .row-work{
            .tab-content{
                display: none;
                opacity: 0;
                transform: translateY(15px);
                animation: fadeIn 0.5s ease 1 forwards;
                .row-work-inr{
                    .col-work-otr{
                        .col-work-inr{
                            background-color: $Gray-Shade-4;
                            border-radius: 24px;
                            .icon-otr{
                                display: flex;
                                align-items: center;
                                background-color: $White-Color;
                                border-radius: 24px;
                                padding: 24px 28px 23px 28px;
                                box-shadow: $box-shadow-custom;
                                .icon-inr{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    position: relative;
                                    margin: 0 20px 0 0;
                                    .bg-icon{
                                        width: 60px;
                                        height: 60px;
                                        border-radius: 16px;
                                        background-color: $Primary-Color-Home-1;
                                    }
                                    .icon{
                                        position: absolute;
                                        z-index: 2;
                                    }
                                }
                                .heading{
                                    color: $Black-Color;
                                }
                            }
                            .desc{
                                color: $Gray-Shade-1;
                                padding: 24px 28px;
                            }
                        }
                        .box1,.box2{
                            @include breakpoint($secreen-max-md){
                                margin-bottom: 24px;
                            }
                        }
                    }
                    .col-work-otr{
                        .box2{
                            display: flex;
                            justify-content: center;
                            flex-direction: column-reverse;
                            @include breakpoint($secreen-max-md){
                                flex-direction: column;
                            }
                            .icon-otr{
                                display: flex;
                                align-items: center;
                                width: 100%;
                                .icon-inr{
                                    .bg-icon{
                                        background-color: $Secondary-Color-Home-1;
                                    }
                                    .icon{
                                    }
                                }
                                .heading{
                                }
                            }
                            .desc{
                            }
                        }
                    }
                }
            }
            .active{
                display: block;
            }
        }
    }
}
.how-it-work{
    margin: 96px 0 128px 0 ;
    @include breakpoint($secreen-max-sm){
        margin: 48px 0 80px 0;
    } 
}

/*------------------------------------------
          Work End Here
-------------------------------------------*/