

/*------------------------------------------
            Navbar Start Here
-------------------------------------------*/

.navbar-main-2{
    .container-fluid{
        .navbar-inr-2{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 32px 0;
            @include breakpoint($secreen-max-md){
                padding: 24px 0;
            }
            .burger-menu{
                display: flex;
                align-items: center;
                .burger-icon-otr{
                    width: 52px;
                    height: 52px;
                    border-radius: 100%;
                    background-color: $Gray-Shade-4;
                    position: relative;
                    margin-right: 12px;
                    display: none;
                    @include breakpoint ($secreen-max-md){
                        display: block;
                    }
                    @include breakpoint($secreen-xxs){
                        width: 44px;
                        height: 44px;
                    }
                    .burger-icon{
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        &:hover{
                            cursor: pointer;
                            path{
                                stroke: $Primary-Color-Home-1;
                            }
                        }
                        path{
                            stroke: $Gray-Shade-2;
                            transition: .3s;
                        }
                    }
                }
                .logo-otr{
                    .logo-img{
                        width: 100px;
                    }
                }
            }
            .input-main{
                position: relative;
                @include breakpoint($secreen-max-md){
                    display: none;
                }
                .input{
                    width: 320px;
                    padding-right: 52px;
                    @include breakpoint($secreen-max-xxxl){
                        width: 100%;
                    }
                    @include breakpoint($secreen-max-lg){
                        width: 180px;
                    }
                }
                .search-icon{
                    position: absolute;
                    right: 24px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            .navigation-otr{
                @include breakpoint($secreen-max-md){
                    display: none;
                }
                .navigation-inr{
                    display: flex;
                    align-items: center;
                    .nav-li{
                        position: relative;
                        &:not(:last-child){
                            margin-right: 40px;
                            @include breakpoint($secreen-max-lg){
                                margin-right: 20px;
                            }
                        }
                        &:hover{
                            .dropdown-otr{
                                top: 50px;
                                opacity: 1;
                                visibility: visible;
                                filter: blur(0);
                            }
                        }
                        .nav-a{
                            color: $Gray-Shade-1;
                            line-height: 50px;
                            transition: .3s;
                            &:hover{
                                color: $Primary-Color-Home-1;
                            }
                        }
                        .dropdown-otr{
                            position: absolute;
                            top: 100px;
                            left: -24px;
                            width: 220px;
                            padding: 16px 24px;
                            opacity: 0;
                            visibility: hidden;
                            background-color: $White-Color;
                            box-shadow: $box-shadow-custom;
                            filter: blur(10px);
                            border-radius: 24px;
                            z-index: 20;
                            transition: .5s;
                            .dropdown-li{
                                position: relative;
                                &:not(:last-child){
                                    margin-bottom: 8px;
                                }
                                &:hover{
                                    .other-dropdown-otr{
                                        opacity: 1;
                                        visibility: visible;
                                        top: -24px;
                                        filter: blur(0);
                                    }
                                }
                                .dropdown-a{
                                    position: relative;
                                    display: inline-flex;
                                    color: $Gray-Shade-2;
                                    transition: .3s;
                                    &:hover{
                                        color: $Primary-Color-Home-1;
                                        padding-left: 16px;
                                    }
                                    &:hover::after{
                                        opacity: 1;
                                        visibility: visible;
                                        left: 0;
                                    }
                                    &::after{
                                        content: "";
                                        position: absolute;
                                        width: 8px;
                                        height: 8px;
                                        background-color: $Primary-Color-Home-1;
                                        top: 50%;
                                        left: -16px;
                                        transform: translateY(-50%);
                                        border-radius: 50px;
                                        opacity: 0;
                                        visibility: hidden;
                                        transition: .3s;
                                    }
                                }
                                .other-dropdown-otr{
                                    position: absolute;
                                    top: 0px;
                                    left: 170px;
                                    width: 220px;
                                    padding: 16px 24px;
                                    background-color: $White-Color;
                                    box-shadow: $box-shadow-custom;
                                    filter: blur(10px);
                                    border-radius: 24px;
                                    opacity: 0;
                                    visibility: hidden;
                                    transition: .5s;
                                    z-index: 30;
                                    .other-dropdown-li{
                                        position: relative;
                                        &:not(:last-child){
                                            margin-bottom: 8px;
                                        }
                                        .other-dropdown-a{
                                            position: relative;
                                            display: inline-flex;
                                            color: $Gray-Shade-2;
                                            transition: .3s;
                                            &:hover{
                                                color: $Primary-Color-Home-1;
                                                padding-left: 16px;
                                            }
                                            &:hover::after{
                                                opacity: 1;
                                                visibility: visible;
                                                left: 0;
                                            }
                                            &::after{
                                                content: "";
                                                position: absolute;
                                                width: 8px;
                                                height: 8px;
                                                background-color: $Primary-Color-Home-1;
                                                top: 50%;
                                                left: -16px;
                                                transform: translateY(-50%);
                                                border-radius: 50px;
                                                opacity: 0;
                                                visibility: hidden;
                                                transition: .3s;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .display{
                        @include breakpoint($secreen-max-lg){
                            display: none;
                        }
                    }
                    .dots{
                        position: relative;
                        display: none;
                        @include breakpoint($secreen-max-lg){
                            display: block;
                        }
                        .dot-a{
                            color: $Gray-Shade-1;
                            line-height: 50px;
                            transition: .3s;
                            cursor: pointer;
                            &:hover{
                                color: $Primary-Color-Home-1;
                            }
                        }
                        .dropdown-dot{
                            position: absolute;
                            left: -24px;
                            width: 220px;
                            padding: 16px 24px;
                            opacity: 0;
                            visibility: hidden;
                            background-color: $White-Color;
                            box-shadow: $box-shadow-custom;
                            border-radius: 24px;
                            z-index: 20;
                            transition: .5s;
                            .dropdown-li{
                                position: relative;
                                &:not(:last-child){
                                    margin-bottom: 8px;
                                }
                                &:hover{
                                    .other-dropdown-otr{
                                        opacity: 1;
                                        visibility: visible;
                                        top: -24px;
                                        filter: blur(0);
                                    }
                                }
                                .dropdown-a{
                                    position: relative;
                                    display: inline-flex;
                                    color: $Gray-Shade-2;
                                    transition: .3s;
                                    &:hover{
                                        color: $Primary-Color-Home-1;
                                        padding-left: 16px;
                                    }
                                    &:hover::after{
                                        opacity: 1;
                                        visibility: visible;
                                        left: 0;
                                    }
                                    &::after{
                                        content: "";
                                        position: absolute;
                                        width: 8px;
                                        height: 8px;
                                        background-color: $Primary-Color-Home-1;
                                        top: 50%;
                                        left: -16px;
                                        transform: translateY(-50%);
                                        border-radius: 50px;
                                        opacity: 0;
                                        visibility: hidden;
                                        transition: .3s;
                                    }
                                }
                                .other-dropdown-otr{
                                    position: absolute;
                                    top: 0px;
                                    left: 170px;
                                    width: 220px;
                                    padding: 16px 24px;
                                    background-color: $White-Color;
                                    box-shadow: $box-shadow-custom;
                                    filter: blur(10px);
                                    border-radius: 24px;
                                    opacity: 0;
                                    visibility: hidden;
                                    transition: .5s;
                                    z-index: 30;
                                    .other-dropdown-li{
                                        position: relative;
                                        &:not(:last-child){
                                            margin-bottom: 8px;
                                        }
                                        .other-dropdown-a{
                                            position: relative;
                                            display: inline-flex;
                                            color: $Gray-Shade-2;
                                            transition: .3s;
                                            &:hover{
                                                color: $Primary-Color-Home-1;
                                                padding-left: 16px;
                                            }
                                            &:hover::after{
                                                opacity: 1;
                                                visibility: visible;
                                                left: 0;
                                            }
                                            &::after{
                                                content: "";
                                                position: absolute;
                                                width: 8px;
                                                height: 8px;
                                                background-color: $Primary-Color-Home-1;
                                                top: 50%;
                                                left: -16px;
                                                transform: translateY(-50%);
                                                border-radius: 50px;
                                                opacity: 0;
                                                visibility: hidden;
                                                transition: .3s;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .action-otr{
                display: flex;
                align-items: center;
                .btn-create{
                    @include breakpoint($secreen-max-md){
                        display: none;
                    }
                }
                .bell-icon-otr{
                    position: relative;
                    margin: 0 24px;
                    @include breakpoint($secreen-xs){
                        position: inherit;
                    }
                    @include breakpoint($secreen-max-md){
                        margin: 0 24px 0 0;
                    }
                    .icon-inr{
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 52px;
                        height: 52px;
                        background-color: $Gray-Shade-4;
                        border-radius: 100%;
                        transition: .3s;
                        cursor: pointer;
                        @include breakpoint($secreen-xxs){
                            width: 44px;
                            height: 44px;
                        }
                        .bell-icon{

                        }
                        .circle{
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            background-color: $Primary-Color-Home-1;
                            border-radius: 100%;
                            top: 3px;
                            right: 3px;
                        }
                    }
                    .icon-hover{
                        position: absolute;
                        z-index: 999;
                        right: 0;
                        background-color: $White-Color;
                        width: 370px;
                        border-radius: 24px;
                        padding: 20px 28px 24px 28px;
                        opacity: 0;
                        visibility: hidden;
                        transition: .3s;
                        box-shadow: $box-shadow-custom;
                        @include breakpoint($secreen-xs){
                            width: calc(100% - 30px);
                            margin: 24px 15px 0 15px;
                        }
                        .heading-otr{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin: 0 0 16px 0;
                            .head{
                                color: $Black-Color;
                            }
                            .link-see{
                                color: $Primary-Color-Home-1;
                                cursor: pointer;
                            }
                        }
                        .box-otr{
                            display: flex;
                            align-items: center;
                            &:not(:last-child){
                                margin: 0 0 12px 0;
                            }
                            .img-otr{
                                margin: 0 16px 0 0;
                                cursor: pointer;
                                .art-img{
                                    width: 60px;
                                    border-radius: 16px;
                                }
                            }
                            .content-otr{
                                .desc{
                                    color: $Black-Color;
                                }
                                .desc-2{
                                    color: $Gray-Shade-1;
                                }
                                .desc-3{
                                    color: $Gray-Shade-2;
                                }
                            }
                        }
                    }
                }
                .profile-otr{
                    position: relative;
                    @include breakpoint($secreen-xs){
                        position: inherit;
                    }
                    .profile-inr{
                        position: relative;
                        display: flex;
                        align-items: center;
                        height: 52px;
                        border-radius: 12px;
                        border: 1px solid $Gray-Shade-3;
                        transition: .3s;
                        cursor: pointer;
                        @include breakpoint($secreen-xs){
                            border: none;
                            border-radius: 0;
                        }
                        @include breakpoint($secreen-xxs){
                            height: 44px;
                        }
                        .creator{
                            width: 52px;
                            height: 52px;
                            border-radius: 12px;
                            @include breakpoint($secreen-xxs){
                                width: 44px;
                                height: 44px;
                            }
                        }
                        .price{
                            color: $Black-Color;
                            margin: 0 16px;
                            @include breakpoint($secreen-xs){
                                display: none;
                            }
                        }
                    }
                    .icon-hover-2{
                        position: absolute;
                        z-index: 999;
                        right: 0;
                        background-color: $White-Color;
                        width: 275px;
                        border-radius: 24px;
                        padding: 20px 28px;
                        transition: .3s;
                        box-shadow: $box-shadow-custom;
                        opacity: 0;
                        visibility: hidden;
                        @include breakpoint($secreen-xs){
                            width: calc(100% - 30px);
                            margin: 24px 15px 0 15px;
                        }
                        .heading{
                            color: $Black-Color;
                            margin: 0 0 8px 0;
                        }
                        .copy-icon-otr{
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            .desc{
                                color: $Gray-Shade-2;
                                margin: 0 8px 0 0;
                            }
                        }
                        .box-otr{
                            display: flex;
                            align-items: center;
                            background-color: $Gray-Shade-4;
                            border-radius: 16px;
                            padding: 12px 20px 12px 16px;
                            margin: 20px 0;
                            .img-otr{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 52px;
                                height: 52px;
                                border-radius: 100%;
                                background-color: $White-Color;
                                margin: 0 12px 0 0;
                            }
                            .price-otr{
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                .desc{
                                    color: $Gray-Shade-2;
                                }
                                .heading{
                                    color: $Black-Color;
                                }
                            }
                        }
                        .link-profile-otr{
                            .link-profile-inr{
                                display: flex;
                                align-items: center;
                                &:not(:last-child){
                                    margin: 0 0 8px 0;
                                }
                                &:hover{
                                    .profile{
                                        color: $Primary-Color-Home-1 !important;
                                        margin: 0 0 0 8px;
                                    }
                                    .circle-hover{
                                        display: block;
                                        visibility: visible;
                                        opacity: 1;
                                    }
                                }
                                .profile{
                                    color: $Gray-Shade-1 !important;
                                    transition: .3s !important;
                                }
                                .circle-hover{
                                    display: none;
                                    visibility: hidden;
                                    opacity: 0;
                                    width: 10px;
                                    height: 10px;
                                    background-color: $Primary-Color-Home-1;
                                    border-radius: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.displayblock{
    opacity: 1 !important;
    visibility: visible !important;
}
.nav-home3{
    box-shadow: $box-shadow-custom;
}

/*------------------------------------------
            Navbar End Here
-------------------------------------------*/

/*------------------------------------------
        Explore Artworks Start Here
-------------------------------------------*/

.explore-artwork{
    overflow: hidden;
    padding: 96px 0 128px 0;
    @include breakpoint($secreen-max-sm){
        padding: 56px 0 80px 0 !important;
    }
    .container-fluid{
        .explore-artwork-inr{
            .heading-otr{
                .head-otr{
                    display: flex;
                    align-items: center;
                    .heading{
                        color: $Black-Color;
                    }
                }
            }
            .teb-main{
                @include breakpoint($secreen-xl2){
                    overflow-x: scroll;
                }
                &::-webkit-scrollbar {
                    width: 100%;
                    height: .1px;
                    background-color: #EEEEEE;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #ACACAC;
                    border-radius: 2px;
                }
                .tab-otr{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    @include breakpoint($secreen-xl2){
                        width: 1100px;
                    }
                    .tab-inr{
                        .tabs{
                            display: flex;
                            align-items: center;
                            .tab-link{
                                margin-top: 24px;
                                &:not(:last-child){
                                    margin-right: 24px;
                                }
                                .tab-p{
                                    padding: 4px 16px;
                                    border-radius: 12px;
                                    background-color: $Gray-Shade-4;
                                    color: $Gray-Shade-1;
                                    transition: .3s;
                                    &:hover{
                                        cursor: pointer;
                                    }
                                }
                            }
                            .active{
                                .tab-p{
                                    background-color: $Primary-Color-Home-1;
                                    color: $White-Color;
                                }
                            }
                        }
                    }
                    .slect-main{
                        display: flex;
                        align-items: center;
                        .select {
                            cursor: pointer;
                            display: inline-block;
                            position: relative;
                            margin-top: 24px;
                            &:not(:last-child){
                                margin-right: 24px;
                            }
                            .select-hidden {
                                display: none;
                                visibility: hidden;
                                padding-right: 10px;
                            }
                            .select-styled {
                                background-color: $Gray-Shade-4;
                                color: $Gray-Shade-1;
                                padding: 4px 32px 4px 16px;
                                border-radius: 12px;
                                font-size: $font-size-bs;
                                line-height: $line-height-bsb;
                                font-family: $font-family-DMSans-Bold;
                                &:after {
                                    content:"";
                                    width: 12px;
                                    height: 10px;
                                    position: absolute;
                                    background-image: url(/assets/img/artwork-page-arrow.svg);
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    top: 16px;
                                    right: 16px;
                                }
                                &:active, &.active {
                                    &:after {
                                        top: 16px;
                                        border-color: transparent transparent $White-Color transparent;
                                    }
                                }
                            }
                            .select-options {
                                display: none; 
                                position: absolute;
                                top: 130%;
                                right: 0;
                                width: 200px;
                                z-index: 999;
                                background-color: $White-Color;
                                border-radius: 12px;
                                padding: 16px 0;
                                box-shadow: $box-shadow-custom;
                                li {
                                    font-size: $font-size-bs;
                                    line-height: $line-height-bsb;
                                    font-family: $font-family-DMSans-Bold;
                                    color: $Gray-Shade-1;
                                    border-radius: 12px;
                                    padding: 0 24px 0 24px;
                                    transition: .3s;
                                    &:not(:last-child){
                                        margin: 0 0 4px 0;
                                    }
                                    &:hover {
                                        color: $Primary-Color-Home-1;
                                        background: transparent;
                                        border-radius: 0;
                                    }
                                    &[rel="hide"] {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .line{
                display: flex;
                flex: 1;
                height: 1px;
                background-color: $Gray-Shade-3;
                margin: 48px 0;
            }
            .row-custom-main{
                .tab-content{
                    display: none;
                    opacity: 0;
                    transform: translateY(15px);
                    animation: fadeIn 0.5s ease 1 forwards;
                    .row-custom-inr{
                        .col-otr{
                            padding: 0 12px;
                            @include breakpoint($secreen-max-xxl){
                                width: 33%;
                            }
                            @include breakpoint($secreen-max-md){
                                width: 50%;
                            }
                            @include breakpoint($secreen-custom){
                                width: 100%;
                            }
                            .col-inr{
                                padding: 24px;
                                border: 1px solid $Gray-Shade-3;
                                border-radius: 24px;
                                transition: .3s;
                                &:hover{
                                    border: 1px solid transparent;
                                    box-shadow: $box-shadow-custom;
                                }
                                .img-otr{
                                    position: relative;
                                    .img-inr{
                                        border-radius: 16px;
                                    }
                                    .timer-otr{
                                        position: absolute;
                                        bottom: 0;
                                        left: 0;
                                        width: 120px;
                                        height: 40px;
                                        border: 1px solid $White-Color;
                                        background-color: $Primary-Color-Home-1;
                                        border-radius: 12px;
                                        .countdown{
                                            position: absolute;
                                            left: 65%;
                                            top: 50%;
                                            transform: translate(-50%, -50%);
                                            color: $White-Color;
                                            width: 100%;
                                        }
                                    }
                                }
                                .time-main{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding: 24px 0 20px 0;
                                    .users-main{
                                        position: relative;
                                        display: flex;
                                        align-items: center;
                                        .create-img-otr{
                                            position: relative;
                                            &:hover{
                                                .hover-box1{
                                                    bottom: 40px;
                                                    padding-bottom: 25px;
                                                    opacity: 1;
                                                    visibility: visible;
                                                }
                                                .hover-box2{
                                                    bottom: 40px;
                                                    padding-bottom: 25px;
                                                    opacity: 1;
                                                    visibility: visible;
                                                }
                                            }
                                            .create-img{
                                                .img-create{
                                                    width: 40px;
                                                    border-radius: 12px;
                                                }
                                                .check-otr{
                                                    position: absolute;
                                                    bottom: 0;
                                                    right: 0;
                                                    width: 16px;
                                                    height: 16px;
                                                    background-color: $Primary-Color-Home-1;
                                                    border-radius: 100%;
                                                    border: 2px solid $White-Color;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    .check-icon{
                                                        width: 8px;
                                                    }
                                                }
                                                .check-otr-left{
                                                    right: 8px;
                                                }
                                            }
                                            .left-create{
                                                margin-right: 8px;
                                            }
                                            .hover-box{
                                                position: absolute;
                                                bottom: 60px;
                                                padding-bottom: 50px;
                                                transition: .3s;
                                                z-index: 10;
                                                .hover-box-inr{
                                                    width: 330px;
                                                    background-color: $White-Color;
                                                    border-radius: 24px;
                                                    padding: 32px;
                                                    box-shadow: $box-shadow-custom;
                                                    backdrop-filter: 15px;
                                                    .user-info{
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: space-between;
                                                        .create-img{
                                                            position: relative;
                                                            .img-create{
                                                                width: 60px;
                                                                border-radius: 16px;
                                                            }
                                                            .check-otr{
                                                                position: absolute;
                                                                bottom: 0;
                                                                right: 0;
                                                                width: 20px;
                                                                height: 20px;
                                                                background-color: $Primary-Color-Home-1;
                                                                border-radius: 100%;
                                                                border: 2px solid $White-Color;
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: center;
                                                                .check-icon{
                                                                    width: 10px;
                                                                }
                                                            }
                                                        }
                                                        .follow-btn{
                                                            width: auto;
                                                        }
                                                    }
                                                    .post-title{
                                                        padding: 20px 0 8px 0;
                                                        color: $Black-Color;
                                                    }
                                                    .address-main{
                                                        display: inline-flex;
                                                        align-items: center;
                                                        padding: 0 0 16px 0;
                                                        .address{
                                                            color: $Gray-Shade-2;
                                                            padding-right: 8px;
                                                        }
                                                        .copy-icon{
                                                            path{
                                                                stroke: $Gray-Shade-3;
                                                            }
                                                        }
                                                    }
                                                    .post-desc{
                                                        color: $Gray-Shade-2;
                                                    }
                                                }
                                            }    
                                            .hover-box1{
                                                left: -20px;
                                                opacity: 0;
                                                visibility: hidden;
                                                @include breakpoint($secreen-max-md){
                                                    display: none;
                                                }
                                            }
                                            .hover-box2{
                                                left: -70px;
                                                opacity: 0;
                                                visibility: hidden;
                                                @include breakpoint($secreen-max-md){
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                    .heart-main{
                                        display: flex;
                                        align-items: center;
                                        .heart-otr{
                                            width: 40px;
                                            height: 40px;
                                            border-radius: 100%;
                                            margin-right: 12px;
                                            background-color: $Gray-Shade-4;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            .heart-icon{
                                                position: relative;
                                                top: 1px;
                                                path{
                                                    transition: .5s;
                                                }
                                            }
                                        }
                                        .num{
                                            color: $Gray-Shade-2;
                                        }
                                    }
                                }
                                .box-head{
                                    color: $Black-Color;
                                    padding: 0 0 24px 0;
                                    display: inline-flex;
                                }
                                .bid-main{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding: 12px 20px;
                                    background-color: $Gray-Shade-4;
                                    border-radius: 16px;
                                    .bid{
                                        color: $Gray-Shade-2;
                                    }
                                    .eth{
                                        color: $Black-Color;
                                    }
                                }
                            }
                            .box1,.box2,.box3,.box4,.box5,.box6,.box7,.box8{
                                margin-bottom: 24px;
                            }
                            .box1,.box2,.box3,.box4,.box5,.box6,.box7,.box8,.box9,.box10{
                                @include breakpoint($secreen-max-md){
                                    margin-bottom: 24px;
                                }
                            }
                            .box1,.box2,.box3,.box4,.box5,.box6,.box7,.box8,.box9,.box10,.box11{
                                @include breakpoint($secreen-custom){
                                    margin-bottom: 24px;
                                }
                            }
                        }
                    }
                }
                .active{
                    display: block;
                }
                .col-btn-otr{
                    .col-btn-inr{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 48px 0 0 0;
                    }
                }
            }
        }
    }
}

/*------------------------------------------
        Explore Artworks End Here
-------------------------------------------*/