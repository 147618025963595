




.terms-of-services{
    padding: 96px 0 128px 0;
    @include breakpoint($secreen-max-sm){
        padding: 56px 0 80px 0 !important;
    }
    .container-fluid{
        .head-otr{
            .heading{
                color: $Black-Color;
            }
        }
        .line{
            display: flex;
            flex: 1;
            height: 1px;
            background-color: $Gray-Shade-3;
            margin: 36px 0;
        }
        .wrapper{
            .desc{
                color: $Gray-Shade-1;
            }
            .para{
                padding: 0 0 16px;
            }
            .head{
                margin: 24px 0 16px 0;
                color: $Black-Color;
            }
        }
    }
}